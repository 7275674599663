<template>
  <gli-dialog
    v-if="backupFileToRestore"
    :title="$t('Restore site from backup file')"
    :editedObject="backupFileToRestore"
    :deleteDisabled="true"
    :buttonSaveText="$t('Restore')"
    @save="save"
    @cancel="cancel"
    @click:outside="closeDialog"
  >
    <v-row dense>
      <v-col cols="12" class="">
        <p class="restore-alert text-center">
          {{ $t("Warning! Effects of restoring are permanent, you can't undo them") }}
          <br />
          {{ $t('All data in current site will be overwritten by the backup data') }}
        </p>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-file-input
          :label="$t('Backup\'s ZIP file')"
          prepend-icon="mdi-archive-arrow-up"
          clearable
          accept=".zip"
          v-model="backupFileToRestore.zip"
          show-size
        >
        </v-file-input>
      </v-col>
    </v-row>
    <v-row dense v-if="hasRestoreError">
      <v-col cols="12" class="">
        <p class="restore-error text-center">
          {{ $t('Unable to restore site from backup file') }}
        </p>
      </v-col>
    </v-row>
  </gli-dialog>
</template>

<style lang="scss" scoped>
.restore-error {
  color: var(--v-error-base);
  font-size: 1.2rem;
}
.restore-alert {
  color: var(--v-error-darken1);
}
</style>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'RestoreSiteDialog',

  data() {
    return {};
  },
  computed: {
    ...mapState('site', ['backupFileToRestore', 'hasRestoreError'])
  },
  methods: {
    ...mapActions('site', ['cancelRestore', 'executeRestore']),
    async save() {
      const isSuccessful = await this.executeRestore();
      if (isSuccessful) {
        this.$emit('save');
      }
    },
    cancel() {
      this.cancelRestore();
      this.$emit('cancel', false);
    },
    closeDialog() {
      this.cancelRestore();
      this.$emit('cancel', false);
    }
  }
};
</script>
